<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import propertyView from "@/components/product-properties/properties/propertyView.vue";

/**
 * Starter component
 */
export default {
  page: {
    title: "Properties",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, propertyView },
  data() {
    return {
      title: "Properties",
      items: [
        {
          text: "Dashboard",
          href: "/"
        },
        {
          text: "Properties",
          active: true
        }
      ]
    };
  }
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <propertyView/>
  </Layout>
</template>