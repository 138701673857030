<template>
    <div>
        <simpleLayout :items_count="all_properties.length">
            <template #buttons>
                <button
                    type="button"
                    class="btn btn-success mb-2 mr-2"
                    @click="showPropertyModal"
                >
                    <i class="mdi mdi-plus mr-1"></i> Add Property
                </button>
            </template>
            <template #content>
                <propertyTable @editProduct="handleProductEdit" />
            </template>
        </simpleLayout>

        <!-- wizard for adding/editing products -->
        <!-- //TODO add support for edit payload -->
        <propertyModal v-if="show_modal" :property_id="edit_id" />
    </div>
</template>

<script>
import propertyModal from "./propertyModal.vue";
import propertyTable from "./propertyTable.vue";
import simpleLayout from "@/components/layout-templates/simple-layout.vue";
import { productComputed, productMethods } from "@/state/helpers";
export default {
    components: {
        simpleLayout,
        propertyModal,
        propertyTable,
    },
    data: () => ({
        show_modal: false,
        edit_id: -1,
    }),
    computed: {
        ...productComputed,
        hasProductSelected() {
            return this.selected_product_id != -1;
        },
    },
    methods: {
        ...productMethods,
        showPropertyModal() {
            this.show_modal = true;
            this.$nextTick(() => {
                this.$bvModal.show("propertyModal");
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (
                        event.type == "hidden" &&
                        event.componentId == "propertyModal"
                    ) {
                        this.show_modal = false;
                        this.edit_id = -1;
                    }
                });
            });
        },
        handleProductEdit(property_id) {
            this.edit_id = property_id;
            this.showPropertyModal();
        },
    },
    mounted() {
        if (this.all_properties.length == 0) {
            this.getProperties().then(() => {
                //this.ready = true;
            });
        }
    },
};
</script>

<style></style>
