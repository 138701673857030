<template>
    <b-modal title='Property' id='propertyModal' size="lg" hide-footer>
        <b-overlay :show="busySaving">
            <!-- form -->
            <b-form @submit.prevent="handleSubmit">
                <b-row>
                    <b-col>
                        <label class="mb-0" for="">Property Name:</label>
                        <b-form-input v-model="propertyData.name" size="sm" type="text" required></b-form-input>
                    </b-col>
                    <b-col>
                        <label class="mb-0" for="">Data Type:</label>
                        <b-form-select size="sm" v-model="propertyData.data_type" :options="data_types"></b-form-select>
                    </b-col>
                </b-row>
                <div class="modal-footer">
                    <b-button type="submit" variant="primary">Submit</b-button>
                    <button type="button" class="btn btn-secondary" @click="hideModal()">Close</button>
                </div>
            </b-form>
        </b-overlay>
    </b-modal>

</template>

<script>
import { productMethods, productComputed} from "@/state/helpers"
export default {
    props:{
        property_id:{
            default: -1,
            required: true,
        },
        product_id:{
            default: -1,
            required: false,
        }
    },
    data:() => ({
        propertyData:{
            id: -1,
            name: '',
            data_type: null,
            product_id: "",
        },
        busySaving: false,
        data_types: [
            { value: null, text: 'Please select an option', disabled: true },
            { value: 'string', text: 'String' },
            { value: 'integer', text: 'Integer' },
            { value: 'decimal', text: 'Decimal' },
        ]
    }),
    computed:{
        ...productComputed,
    },
    methods:{
        ...productMethods,
        handleSubmit(){
            this.busySaving = true;
            this.saveProductProperty(this.propertyData).then(()=>{
                this.getProperties().then(()=>{
                    this.busySaving = false;
                    this.hideModal();
                    if(this.propertyData.id == -1){
                        // New item saved
                        this.$swal.fire("Saved", "Product property saved!", "success");
                    }
                    else{
                        // updated item
                        this.$swal.fire("Saved", `Updated property: ${this.propertyData.name}`, "success");
                    }
                    
                });
            });
        },
        hideModal() {
            this.$bvModal.hide("propertyModal");
        },
    },
    mounted(){
        if(this.property_id != -1){
            let property = this.all_properties.find( item => item.id == this.property_id);
            if(property){
                this.propertyData.id = property.id;
                this.propertyData.name = property.name;
                this.propertyData.data_type = property.data_type;
                this.propertyData.product_id = property.product_id;
            }
        }
    },
}
</script>

<style>

</style>